/*
 * @Author: Zheng Lei
 * @Email: baimoc@163.com
 * @Date: 2022-12-25 16:22:44
 * @LastEditTime: 2023-01-02 17:13:02
 * @FilePath: \transcriptome-browser\src\pages\panels\TranscriptomeBrowser.jsx
 */
import React from "react";
import SplitPane from "react-split-pane";

import PanelMenu from "../../components/UEBrowser/PanelMenu";
import Track from "../../components/UEBrowser/Track";
import Gene from "../../components/UEBrowser/Gene";
import Genome from "../../components/UEBrowser/Genome";
import trackData from '../../data/track-data'

export default function MainBrowser() {
  let genome_height = 150;
  let gene_height = 150;
  let genomeData = [];
  let geneData = [];
  // console.log(track_data)
  trackData.forEach((ele) => {
    if (ele.type === "genome") {
      genome_height = ele.style.height;
      genomeData.push(ele);
    } else if (ele.type === "annotation") {
      gene_height = ele.style.height;
      geneData.push(ele);
    }
  });

  return (
    <>
    <div className='panel-body' id='transcriptome-browser'>
      <PanelMenu
          to="/tsbrowser"
          name="Epitranscriptome Browser"
          did="transcriptome-browser"
        ></PanelMenu>
      <div className="panel-content" style={{padding:"0px"}}>
        <SplitPane initialSize="100%" split="horizontal">
          <Genome
            data={genomeData}
            initialSize={genome_height - 1 + "px"}
          ></Genome>
          <Gene data={geneData} initialSize={gene_height - 1 + "px"}></Gene>
          <Track data={trackData}></Track>
        </SplitPane>
      </div>
      </div>
    </>
  );
}
