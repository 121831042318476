/*
 * @Author: Zheng Lei
 * @Email: baimoc@163.com
 * @Date: 2022-11-19 16:24:30
 * @LastEditTime: 2023-01-01 10:40:34
 * @FilePath: \transcriptome-browser\src\index.js
 */
import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom'
import './styles/root.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  // </React.StrictMode>
);

reportWebVitals();
