/*
 * @Author: Zheng Lei
 * @Email: baimoc@163.com
 * @Date: 2022-12-31 16:33:21
 * @LastEditTime: 2022-12-31 16:33:51
 * @FilePath: \transcriptome-browser\src\pages\Asider\Cloud.jsx
 */
import React from 'react'

export default function Cloud() {
  return (
    <div>Cloud</div>
  )
}
