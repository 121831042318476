/*
 * @Author: Zheng Lei
 * @Email: baimoc@163.com
 * @Date: 2022-12-31 15:50:44
 * @LastEditTime: 2023-01-02 11:11:49
 * @FilePath: \transcriptome-browser\src\components\Asider.jsx
 */
import React, { useState } from 'react'
import { Layout } from 'antd'
const { Sider } = Layout
import {
  BankOutlined,
  BookOutlined,
  CloudServerOutlined,
  AppstoreAddOutlined,
  HistoryOutlined,
  HeartOutlined,
  SearchOutlined
} from '@ant-design/icons'
import asideData from '../data/aside-data'

export default function Asider(props) {
  const {getAsideElement} = props;
  const handleSideClick = (label) => {
    asideData.forEach(item => {
      if(item.label === label) {
        getAsideElement(item.element)
      }
    });
    // if (asideLabel !== label) {
    //   setasideLabel(label)
    //   console.log('==', asideLabel, label)
    //   return
    // }
    // let element = document.getElementById('baimo-asider')
    // let currentWidth = element.parentNode.style.width
    // console.log(currentWidth)

    // if (currentWidth)
    //   parseInt(currentWidth.replace('px', '')) > parseInt(asideMixWidth.replace('px', ''))
    //     ? setasideMaxWidth(currentWidth)
    //     : asideMixWidth
    // setAsideWidth(currentWidth === '0px' ? asideMaxWidth : '0px')
  }

  return (
    <div>
      <Sider style={{ width: '48px', height: '100%' }} width='48px'>
        <div className='sider-container'>
          <BankOutlined key='home' className='aside-btn' onClick={()=>{handleSideClick('home')}} />
          <SearchOutlined key='search' className='aside-btn' onClick={()=>{handleSideClick('search')}} />
          <BookOutlined key='book' className='aside-btn' onClick={()=>{handleSideClick('book')}} />

          <HistoryOutlined key='histroy' className='aside-btn' onClick={()=>{handleSideClick('histroy')}} />
          {/* <HeartOutlined key='love' className='aside-btn' onClick={()=>{handleSideClick('love')}} /> */}
          <CloudServerOutlined key='cloud' className='aside-btn' onClick={()=>{handleSideClick('cloud')}} />
          <AppstoreAddOutlined key='plugin' className='aside-btn' onClick={()=>{handleSideClick('plugin')}} />
        </div>
      </Sider>
    </div>
  )
}
