/*
 * @Author: Zheng Lei
 * @Email: baimoc@163.com
 * @Date: 2022-11-20 09:02:25
 * @LastEditTime: 2023-01-02 19:20:20
 * @FilePath: \transcriptome-browser\src\routes\index.jsx
 */
import React from 'react'
import Home from '../pages/Home'
import TBrowser from '../pages/UEBrowser'
import Plugins from '../pages/Plugins'
import Downloads from '../pages/Downloads'
import Tutorial from '../pages/Tutorial'
import Contact from '../pages/Contact'
import MainWindow from '../pages/MainWindow'
import TranscriptomeBrowser from '../pages/panels/TranscriptomeBrowser'
import { nanoid } from "nanoid";
import ThreeDGenome from '../pages/panels/3DGenome'
import InteractionNetwork from '../pages/panels/InteractionNetwork'
import RNAExpression from '../pages/panels/RNAExpression'
import RNASequence from '../pages/panels/RNASequence'
import RNAStructure from '../pages/panels/RNAStructure'

export const navbarData =  [
  {
    key: nanoid(),
    title: 'Home',
    path: '/',
    element: <Home/>,
  },
  {
    key: nanoid(),
    title: 'UEBrowser',
    path: '/tbrowser',
    element: <TBrowser/>,
  },
  {
    key: nanoid(),
    title: 'Main',
    path: '/main',
    element: <MainWindow/>,
  },
  {
    key: nanoid(),
    title: 'TranscriptomeBrowser',
    path: '/tsbrowser',
    element: <TranscriptomeBrowser/>,
  },
  {
    key: nanoid(),
    title: '3D Genome',
    path: '/3dgenome',
    element: <ThreeDGenome/>,
  },
  {
    key: nanoid(),
    title: 'Interaction Network',
    path: '/interactionnetwork',
    element: <InteractionNetwork/>,
  },
  {
    key: nanoid(),
    title: 'RNA Expression',
    path: '/rnaexpression',
    element: <RNAExpression/>,
  },
  {
    key: nanoid(),
    title: 'RNA Sequence',
    path: '/rnasequence',
    element: <RNASequence/>,
  },
  {
    key: nanoid(),
    title: 'RNA Structure',
    path: '/rnastructure',
    element: <RNAStructure/>,
  },
  {
    key: nanoid(),
    title: 'Plugins',
    path: '/plugins',
    element: <Plugins/>,
  },
  {
    key: nanoid(),
    title: 'Downloads',
    path: '/downloads',
    element: <Downloads/>,
  },
  {
    key: nanoid(),
    title: 'Tutorial',
    path: '/tutorial',
    element: <Tutorial/>,
  },
  {
    key: nanoid(),
    title: 'Contact',
    path: '/contact',
    element: <Contact/>,
  }
]

export const navbarKey = navbarData.map(item=>item.key)