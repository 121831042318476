/*
 * @Author: Zheng Lei
 * @Email: baimoc@163.com
 * @Date: 2022-12-31 16:32:07
 * @LastEditTime: 2022-12-31 16:32:27
 * @FilePath: \transcriptome-browser\src\pages\Asider\Home.jsx
 */
import React from 'react'

export default function Home() {
  return (
    <div>Home</div>
  )
}
