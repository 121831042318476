import React from 'react'
import PanelMenu from '../../components/UEBrowser/PanelMenu'

export default function RNAStructure() {
  return (
      <>
      <div className='panel-body' id='rna-structure'>
        <PanelMenu to='/rnastructure' name='RNA Structure' did='rna-structure'></PanelMenu>
        <div className="panel-content">
        RNA Structure Panel
        </div>
      </div>
    </>
  )
}
