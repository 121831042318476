/*
 * @Author: Zheng Lei
 * @Email: baimoc@163.com
 * @Date: 2022-12-28 16:59:11
 * @LastEditTime: 2023-01-02 17:05:46
 * @FilePath: \transcriptome-browser\src\pages\panels\InteractionNetwork.jsx
 */
import React from 'react'
import { Fragment } from 'react'
import PanelMenu from '../../components/UEBrowser/PanelMenu'

export default function RNAExpression() {
  return (
    <>
      <div className='panel-body' id='interaction-network'>
      <PanelMenu to='/interactionnetwork' name='Interaction Network' did='interaction-network'></PanelMenu>
      <div className="panel-content">
      Interaction Network Panel
        </div>
      </div>
    </>
  )
}
