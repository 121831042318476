/*
 * @Author: Zheng Lei
 * @Email: baimoc@163.com
 * @Date: 2022-11-19 16:24:30
 * @LastEditTime: 2023-01-01 15:40:05
 * @FilePath: \transcriptome-browser\src\App.js
 */
// import Home from './pages/Home'
import React, { useState, useEffect }  from "react";
import {useRoutes, useNavigate} from 'react-router-dom'
import './styles/App.css';
import {navbarData} from './routes/index.jsx'
import { Layout, Menu, Affix } from "antd";
import RouterLayout from './components/RouterLayout'

const { Header} = Layout;
// const NavItems = ["Home", "TBrowser", "Plugins", "Downloads", "Tutorial", "Contact"].map((key) => ({
//   key,
//   label: `${key}`,
// }));


function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function App() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const {height:winHeight} = windowDimensions;
  const element = useRoutes(navbarData)

  const navigate = useNavigate()
  function clickNav(e) {
    console.log("11111", e)
    let res = navbarData.filter((item)=>{
      return item.key === e.key
    })
    console.log(res, res[0].path)
    navigate(res[0].path)
  }
  
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  

  return (
    <Layout>
      {/* <Affix offsetTop={0}> */}
      <Header className="header"
      style={
        {
          display: 'none',
        }
      }
      >
        <div className="logo" />
        <Menu
        
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={["1"]}
          items={navbarData}
          onClick={clickNav}
        />
        
      </Header>
      {/* </Affix> */}
      <Layout style={{height: winHeight-33+"px", background: 'white'}}>
      <RouterLayout>
        {element}
      </RouterLayout>
      
      </Layout>
    </Layout>
  );
}

export default App;
