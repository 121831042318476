/*
 * @Author: Zheng Lei
 * @Email: baimoc@163.com
 * @Date: 2022-11-19 18:02:51
 * @LastEditTime: 2022-11-19 18:03:40
 * @FilePath: \transcriptome-browser\src\pages\Downloads.jsx
 */
import React from 'react'

export default function Downloads() {
  return (
    <div>Downloads</div>
  )
}
