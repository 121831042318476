/*
 * @Author: Zheng Lei
 * @Email: baimoc@163.com
 * @Date: 2023-01-01 10:28:01
 * @LastEditTime: 2023-01-01 11:28:13
 * @FilePath: \transcriptome-browser\src\components\ResizeHandle.tsx
 */
import { PanelResizeHandle } from 'react-resizable-panels'

// import styles from '../styles/style.module.css'

export default function ResizeHandle({ className = '', id }: { className?: string; id?: string }) {
  return (
    <PanelResizeHandle className={['pandel-resizer',className].join(' ')} id={id}>
      {/* <div className={styles.ResizeHandleInner}> */}
        {/* <svg className={styles.Icon} viewBox='0 0 24 24'>
          <path
            fill='currentColor'
            d='M8,18H11V15H2V13H22V15H13V18H16L12,22L8,18M12,2L8,6H11V9H2V11H22V9H13V6H16L12,2Z'
          />
        </svg> */}
      {/* </div> */}
    </PanelResizeHandle>
  )
}

// import React from 'react'

// export default function ResizeHandle() {
//   return (
//     <div>ResizeHandle</div>
//   )
// }
