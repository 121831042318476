/*
 * @Author: Zheng Lei
 * @Email: baimoc@163.com
 * @Date: 2022-12-31 16:33:35
 * @LastEditTime: 2022-12-31 16:33:41
 * @FilePath: \transcriptome-browser\src\pages\Asider\Plugins.jsx
 */
import React from 'react'

export default function Plugins() {
  return (
    <div>Plugins</div>
  )
}
