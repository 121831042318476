/*
 * @Author: Zheng Lei
 * @Email: baimoc@163.com
 * @Date: 2022-11-26 14:58:35
 * @LastEditTime: 2022-11-30 12:07:30
 * @FilePath: \transcriptome-browser\src\components\UEBrowser\Genome.jsx
 */
import React from "react";
// import Aside from "../UEBrowser/Aside"

export default function Header(props) {
  // const {data} = props
  return (
    <div style={{position: 'relative'}}>
      {/* <Aside data={data} initialSize="200px"></Aside> */}
      <svg style={{ width: "100%", height: "40px", padding: "10px" }}>
        <g transform="translate(-0)">
          <path
            d="M8,0h7v20h-7a8,8 0 0 1 -8,-8v-4a8,8 0 0 1 8,-8z"
            fill="#ced6e0"
          ></path>
          <rect
            x="15"
            y="0"
            width="20"
            height="20"
            fill="#57606f"
          ></rect>
          <rect
            x="35"
            y="0"
            width="12"
            height="20"
            fill="#ced6e0"
          ></rect>
          <rect
            x="47"
            y="0"
            width="13"
            height="20"
            fill="#57606f"
          ></rect>
          <rect
            x="60"
            y="0"
            width="22"
            height="20"
            fill="#ced6e0"
          ></rect>
          <rect x="82" y="0" width="23" height="20" fill="rgb(85,85,85)"></rect>
          <rect
            x="105"
            y="0"
            width="27"
            height="20"
            fill="#ced6e0"
          ></rect>
          <rect
            x="132"
            y="0"
            width="23"
            height="20"
            fill="#57606f"
          ></rect>
          <rect
            x="155"
            y="0"
            width="27"
            height="20"
            fill="#ced6e0"
          ></rect>
          <rect
            x="182"
            y="0"
            width="14"
            height="20"
            fill="#57606f"
          ></rect>
          <rect
            x="196"
            y="0"
            width="14"
            height="20"
            fill="#ced6e0"
          ></rect>
          <rect
            x="210"
            y="0"
            width="15"
            height="20"
            fill="#57606f"
          ></rect>
          <rect
            x="225"
            y="0"
            width="35"
            height="20"
            fill="#ced6e0"
          ></rect>
          <rect
            x="260"
            y="0"
            width="26"
            height="20"
            fill="#57606f"
          ></rect>
          <rect
            x="286"
            y="0"
            width="18"
            height="20"
            fill="#ced6e0"
          ></rect>
          <rect
            x="304"
            y="0"
            width="25"
            height="20"
            fill="#2f3542"
          ></rect>
          <rect
            x="329"
            y="0"
            width="35"
            height="20"
            fill="#ced6e0"
          ></rect>
          <rect
            x="364"
            y="0"
            width="19"
            height="20"
            fill="rgb(85,85,85)"
          ></rect>
          <rect
            x="383"
            y="0"
            width="15"
            height="20"
            fill="#ced6e0"
          ></rect>
          <rect
            x="398"
            y="0"
            width="49"
            height="20"
            fill="rgb(85,85,85)"
          ></rect>
          <rect
            x="447"
            y="0"
            width="6"
            height="20"
            fill="#ced6e0"
          ></rect>
          <rect x="453" y="0" width="98" height="20" fill="#2f3542"></rect>
          <rect
            x="551"
            y="0"
            width="23"
            height="20"
            fill="#ced6e0"
          ></rect>
          <rect
            x="574"
            y="0"
            width="23"
            height="20"
            fill="#2f3542"
          ></rect>
          <rect
            x="597"
            y="0"
            width="18"
            height="20"
            fill="#ced6e0"
          ></rect>
          <rect
            x="615"
            y="0"
            width="32"
            height="20"
            fill="#2f3542"
          ></rect>
          <rect
            x="647"
            y="0"
            width="17"
            height="20"
            fill="#ced6e0"
          ></rect>
          <rect x="664" y="0" width="32" height="20" fill="#2f3542"></rect>
          <rect
            x="696"
            y="0"
            width="30"
            height="20"
            fill="#ced6e0"
          ></rect>
          <rect
            x="726"
            y="0"
            width="28"
            height="20"
            fill="rgb(85,85,85)"
          ></rect>
          <rect
            x="754"
            y="0"
            width="11"
            height="20"
            fill="#ced6e0"
          ></rect>
          <rect
            x="765"
            y="0"
            width="18"
            height="20"
            fill="rgb(85,85,85)"
          ></rect>
          <rect
            x="783"
            y="0"
            width="6"
            height="20"
            fill="#ced6e0"
          ></rect>
          <polygon points="789,0,812,10,789,20" fill="#ED4C67"></polygon>
          <polygon points="812,10,837,0,837,20" fill="#ED4C67"></polygon>
          <rect x="837" y="0" width="89" height="20" fill="#2f3542"></rect>
          <rect
            x="926"
            y="0"
            width="28"
            height="20"
            fill="#ced6e0"
          ></rect>
          <rect
            x="954"
            y="0"
            width="22"
            height="20"
            fill="rgb(85,85,85)"
          ></rect>
          <rect
            x="976"
            y="0"
            width="30"
            height="20"
            fill="#ced6e0"
          ></rect>
          <rect
            x="1006"
            y="0"
            width="10"
            height="20"
            fill="rgb(85,85,85)"
          ></rect>
          <rect
            x="1016"
            y="0"
            width="17"
            height="20"
            fill="#ced6e0"
          ></rect>
          <rect
            x="1033"
            y="0"
            width="9"
            height="20"
            fill="rgb(85,85,85)"
          ></rect>
          <rect
            x="1042"
            y="0"
            width="33"
            height="20"
            fill="#ced6e0"
          ></rect>
          <rect
            x="1075"
            y="0"
            width="11"
            height="20"
            fill="rgb(85,85,85)"
          ></rect>
          <rect
            x="1086"
            y="0"
            width="24"
            height="20"
            fill="#ced6e0"
          ></rect>
          <rect
            x="1110"
            y="0"
            width="13"
            height="20"
            fill="#2f3542"
          ></rect>
          <rect
            x="1123"
            y="0"
            width="20"
            height="20"
            fill="#ced6e0"
          ></rect>
          <rect
            x="1143"
            y="0"
            width="28"
            height="20"
            fill="rgb(85,85,85)"
          ></rect>
          <rect
            x="1171"
            y="0"
            width="35"
            height="20"
            fill="#ced6e0"
          ></rect>
          <rect x="1206" y="0" width="33" height="20" fill="#2f3542"></rect>
          <rect
            x="1239"
            y="0"
            width="19"
            height="20"
            fill="#ced6e0"
          ></rect>
          <rect x="1258" y="0" width="32" height="20" fill="#2f3542"></rect>
          <rect
            x="1290"
            y="0"
            width="55"
            height="20"
            fill="#ced6e0"
          ></rect>
          <rect
            x="1345"
            y="0"
            width="28"
            height="20"
            fill="#57606f"
          ></rect>
          <rect
            x="1373"
            y="0"
            width="20"
            height="20"
            fill="#ced6e0"
          ></rect>
          <rect x="1393" y="0" width="62" height="20" fill="#2f3542"></rect>
          <rect
            x="1455"
            y="0"
            width="3"
            height="20"
            fill="#ced6e0"
          ></rect>
          <rect
            x="1458"
            y="0"
            width="16"
            height="20"
            fill="#57606f"
          ></rect>
          <rect
            x="1474"
            y="0"
            width="24"
            height="20"
            fill="#ced6e0"
          ></rect>
          <rect
            x="1498"
            y="0"
            width="26"
            height="20"
            fill="rgb(85,85,85)"
          ></rect>
          <rect
            x="1524"
            y="0"
            width="12"
            height="20"
            fill="#ced6e0"
          ></rect>
          <rect
            x="1536"
            y="0"
            width="46"
            height="20"
            fill="#2f3542"
          ></rect>
          <path
            d="M1582,0h26a8,8 0 0 1 8,8v4a8,8 0 0 1 -8,8h-26z"
            fill="#ced6e0"
          ></path>
        </g>
      </svg>
    </div>
  );
}
