/*
 * @Author: Zheng Lei
 * @Email: baimoc@163.com
 * @Date: 2023-01-01 10:11:31
 * @LastEditTime: 2023-01-02 20:04:13
 * @FilePath: \transcriptome-browser\src\pages\MainWindow.jsx
 */
import React, { useState } from 'react'
import { Layout } from 'antd'
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels'

import {
  BankOutlined,
  BookOutlined,
  CloudServerOutlined,
  AppstoreAddOutlined,
  HistoryOutlined,
  HeartOutlined,
  SearchOutlined,
  CloseOutlined,
  QuestionOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
  BlockOutlined,
  CameraOutlined,
  ControlOutlined,
  SettingOutlined,
  ExclamationCircleOutlined,
  BugOutlined
} from '@ant-design/icons'

import Home from './Asider/Home'
import Menu from '../components/UEBrowser/Menu'
import PanelMenu from '../components/UEBrowser/PanelMenu'
import TranscriptomeBrowser from './panels/TranscriptomeBrowser'
import ThreeDGenome from './panels/3DGenome'
import InteractionNetwork from './panels/InteractionNetwork'
import RNASequence from './panels/RNASequence'
import RNAStructure from './panels/RNAStructure'
import RNAExpression from './panels/RNAExpression'
import ResizeHandle from '../components/ResizeHandle.tsx'
import Asider from '../components/Asider'
import panelsData from '../data/panels-data'

import '../styles/UEBrowser.css'
import styles from '../styles/style.module.css'

const { Header, Footer, Sider, Content } = Layout

export default function MainWindow() {
  const [winHeight, setWinHeight] = useState(window.innerHeight)
  const [asideElement, setasideElement] = useState(<Home />)
  const [isShowAside, setisShowAside] = useState(true)
  const asideMixWidth = '200px'
  const [asideMaxWidth, setasideMaxWidth] = useState(asideMixWidth)
  const [asideWidth, setAsideWidth] = useState(asideMixWidth)

  window.onresize = function () {
    console.log('window.onresize')
    setWinHeight(window.innerHeight)
    // app.renderer.resize(window.innerWidth, window.innerHeight);
  }

  const getAsideElement = (ele) => {
    if (asideElement.type.name !== ele.type.name) {
      setasideElement(ele)
    } else {
      setisShowAside(!isShowAside)

      if (!isShowAside) {
        return
      }
      let element = document.getElementById('baimo-asider')
      let currentWidth = element.parentNode.offsetWidth + 'px'
      console.log(currentWidth)

      if (currentWidth)
        parseInt(currentWidth.replace('px', '')) > parseInt(asideMixWidth.replace('px', ''))
          ? setasideMaxWidth(currentWidth)
          : asideMixWidth
      if (currentWidth === '0px') {
        setisShowAside(true)
        element.parentNode.style.flex = '19.6921 1 0px'
        // setAsideWidth(asideMaxWidth)
        console.log('currentWidth', element.parentNode)
      } else {
        setisShowAside(!isShowAside)
      }
      console.log('asideWidth', currentWidth, asideMaxWidth, asideWidth)
    }
  }

  return (
    <div>
      <Layout>
        <Header>
          <Menu></Menu>
        </Header>
        <Layout style={{ height: winHeight - 54 }}>
          <Asider getAsideElement={getAsideElement}></Asider>
          <Content>
            <div className='container'>
              <div className='bottom-row'>
                <PanelGroup autoSaveId='baimo' direction='horizontal'>
                  {isShowAside && (
                    <>
                      <Panel className='panel' defaultSize={10} order={1} minSize={0}>
                        <div id='baimo-asider' className='aside-content'>
                          {asideElement}
                        </div>
                      </Panel>
                      <ResizeHandle />
                    </>
                  )}
                  {/* {panelsData.forEach((item) => {
                    if (item.position.includes('left')) {
                      console.log(item.element)
                      return (
                        <Panel className="panel" defaultSize={70} order={2} minSize={0}>
                          item.element
                        </Panel>
                      )
                    }
                  })} */}

                  <Panel className='panel' defaultSize={70} order={2} minSize={0}>
                    <PanelGroup direction='vertical'>
                      <Panel className='panel' defaultSize={70} order={1} minSize={3}>
                        <TranscriptomeBrowser />
                      </Panel>
                      <ResizeHandle />

                      <Panel className='panel' order={3} minSize={3}>
                        <PanelGroup direction='horizontal'>
                          <Panel className='panel' defaultSize={70} order={1} minSize={3}>
                            <RNASequence></RNASequence>
                          </Panel>
                          <ResizeHandle />

                          <Panel className='panel' order={3} minSize={3}>
                            <RNAStructure></RNAStructure>
                          </Panel>
                        </PanelGroup>
                      </Panel>
                    </PanelGroup>
                  </Panel>
                  <ResizeHandle />

                  <Panel className='panel' defaultSize={20} order={3} minSize={0}>
                    <PanelGroup direction='vertical'>
                      <Panel className='panel' defaultSize={30} order={1} minSize={3}>
                        <RNAExpression></RNAExpression>
                      </Panel>
                      <ResizeHandle />

                      <Panel className='panel' defaultSize={40} order={2} minSize={3}>
                        <ThreeDGenome></ThreeDGenome>
                      </Panel>

                      <ResizeHandle />
                      <Panel className='panel' defaultSize={30} order={3} minSize={3}>
                        <InteractionNetwork></InteractionNetwork>
                      </Panel>
                    </PanelGroup>
                  </Panel>
                </PanelGroup>
              </div>
            </div>
          </Content>
        </Layout>
        <Footer>
          <ExclamationCircleOutlined className='footer-btn' />
          <BugOutlined className='footer-btn' />
        </Footer>
      </Layout>
    </div>
  )
}
