/*
 * @Author: Zheng Lei
 * @Email: baimoc@163.com
 * @Date: 2023-01-02 10:09:18
 * @LastEditTime: 2023-01-02 11:28:56
 * @FilePath: \transcriptome-browser\src\data\panels-data.js
 */
import TranscriptomeBrowser from '../pages/panels/TranscriptomeBrowser'
import ThreeDGenome from '../pages/panels/3DGenome'
import InteractionNetwork from '../pages/panels/InteractionNetwork'
import RNAExpression from '../pages/panels/RNAExpression'

const panelsData = [
  {
    id:"transcriptome-browser",
    name:"Epitranscriptome Browser",
    to:"/tsbrowser",
    element:<TranscriptomeBrowser/>,
    position:"left-1",
    defaultSize: 70
  },
  {
    id:"rna-expression",
    name:"RNA Expression Image",
    to:"/rnaexpression",
    element:<RNAExpression/>,
    position:"right-1",
  },
  {
    id:"threed-genome",
    name:"3D Genome",
    to:"/3dgenome",
    element:<ThreeDGenome/>,
    position:"right-2",
  },
  {
    id:"interaction-network",
    name:"Interaction Network",
    to:"/interactionnetwork",
    element:<InteractionNetwork/>,
    position:"right-3",
  }
]

export default panelsData