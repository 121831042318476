/*
 * @Author: Zheng Lei
 * @Email: baimoc@163.com
 * @Date: 2023-01-02 10:30:40
 * @LastEditTime: 2023-01-02 11:08:18
 * @FilePath: \transcriptome-browser\src\data\aside-data.js
 */
import Home from '../pages/Asider/Home'
import Search from '../pages/Asider/Search'
import BookMark from '../pages/Asider/BookMark'
import History from '../pages/Asider/History'
import Cloud from '../pages/Asider/Cloud'
import Plugins from '../pages/Asider/Plugins'

const asideData = [
  {
    label: 'home',
    element: <Home />
  },
  {
    label: 'search',
    element: <Search />
  },
  {
    label: 'book',
    element: <BookMark />
  },
  {
    label: 'histroy',
    element: <History />
  },
  {
    label: 'cloud',
    element: <Cloud />
  },
  {
    label: 'plugin',
    element: <Plugins />
  },
]

export default asideData