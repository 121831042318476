/*
 * @Author: Zheng Lei
 * @Email: baimoc@163.com
 * @Date: 2022-11-28 10:53:07
 * @LastEditTime: 2022-11-29 20:24:08
 * @FilePath: \transcriptome-browser\src\components\UEBrowser\Aside.jsx
 */
import React from "react";
import {nanoid} from 'nanoid'

export default function Aside(props) {
  const { data} = props;
  return (
    <div>
      <div style={{position: "absolute",
              top: "0px",
              left: "0px",}}>
      {data.map((track) => {
        return (
          <div
            key={nanoid()}
            style={{
              height: track.type === "track" ? track.style.height:0,
            }}
          >
            <div className="aside-track">
            <p className="no-selected" key={nanoid()}>
            {track.name}
            </p>
            </div>
          </div>
        );
      })}
      </div>
    </div>
  );
}
