/* eslint-disable */
/*
 * @Author: Zheng Lei
 * @Email: baimoc@163.com
 * @Date: 2022-11-24 09:28:29
 * @LastEditTime: 2022-12-25 16:13:17
 * @FilePath: \transcriptome-browser\src\components\UEBrowser\Render\BaseRender.jsx
 */

import React, { useEffect, useRef } from "react";
import { Application, Graphics, Container, TextStyle, Text } from "pixi.js";
import "@pixi/events";

// 初始化
let track_background = 0xffffff
let track_color = 0x70a1ff
let track_height = 50;


const app = new Application({
  width: window.innerWidth,
  height: track_height*10,
  background: track_background,
  useContextAlpha: false,
  antialias: false,
  interactive: true,
  resolution: 1
  // resizeTo: window,
});

window.onresize = function()
{ 
  console.log("onresize")
    app.renderer.resize(window.innerWidth, window.innerHeight);
}

let dragTarget = {};
let graphics = new Graphics();
const container = new Container();
let p_offer = {};
let paint_offset_x = 200

function generateRect(data) {
  let track_values = data.values;
  let num = data.id;
  let track_color = data.style.color;
  // console.log(track_values)
  let ele_width = 1;
  let ele_max = track_values.length
  
  track_values.forEach((value, index) => {
    graphics.beginFill(track_color)
    .drawRect(
      ele_width + ele_width * index - paint_offset_x,
      track_height*(num+1) - value,
      ele_width,
      value
    )
    .endFill();
  });

  // 拖拽图层
  let dragLayer = new Graphics();
  dragLayer.beginFill(0xff0000).drawRect(
    0,
    0,
    ele_max*ele_width,
    track_height*(num+1)
  )
  .endFill();
  dragLayer.alpha = 0;
  
  // 指针图层
  // let cursor_line = new Graphics();
  // cursor_line.beginFill(0x2f3542).drawRect(
  //   0,
  //   0,
  //   1,
  //   track_height
  // )
  // .endFill();

  graphics.cursor = "pointer";
  container.interactive = true;
  container.on('pointerdown', onDragStart, container);

  container.addChild(dragLayer);
  container.addChild(graphics);
  // container.addChild(cursor_line);
  app.stage.addChild(container);

  // container.on('pointermove', (event)=>{
  //   cursor_line.x = event.global.x
  //   cursor_line.y = 0
  // });
}

function onDragStart(event) {
  p_offer.x =  event.global.x - container.x
  p_offer.y =  event.global.y - container.y
  this.alpha = 0.5;
  dragTarget = this;
  app.stage.on('pointermove', onDragMove);
}

function onDragMove(event) {
  container.x =  event.global.x - p_offer.x
  container.y =  0
}

function onDragEnd() {
  if (dragTarget) {
      app.stage.off('pointermove', onDragMove);
      dragTarget.alpha = 1;
      dragTarget = null;
  }
}

function processData(track_data) {
  track_data
}


export default function BaseRender(props) {
  const {data:track_data, total} = props
  // console.log(props)
  const ref = useRef(null);
  // console.time("渲染计时");
  app.renderer.resize(window.innerWidth,total*track_data.style.height);
  
  generateRect(track_data);
  document.getElementById("track")
  // generateTrackAside(track_data)
  
  // processData(track_data)
  
  app.stage.interactive = true;
  app.stage.hitArea = app.screen;
  app.stage.on('pointerup', onDragEnd);
  app.stage.on('pointerupoutside', onDragEnd);
  app.stage.addEventListener("click", (e) => {
    let prefix;

    switch (e.detail) {
      case 1:
        prefix = "single";
        break;
      case 2:
        prefix = "double";
        break;
      case 3:
        prefix = "triple";
        break;
      default:
        prefix = e.detail + "th";
        break;
    }

    // console.log("That was a " + prefix + "click");
  });

  // console.timeEnd("渲染计时");

  useEffect(() => {
    ref.current.appendChild(app.view);
    app.start();

    return () => {
      app.stop();
    };
  }, []);
  return <div id="track" ref={ref}></div>;
}
