/*
 * @Author: Zheng Lei
 * @Email: baimoc@163.com
 * @Date: 2022-12-31 16:33:10
 * @LastEditTime: 2022-12-31 16:33:46
 * @FilePath: \transcriptome-browser\src\pages\Asider\History.jsx
 */
import React from 'react'

export default function History() {
  return (
    <div>History</div>
  )
}
