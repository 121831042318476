/*
 * @Author: Zheng Lei
 * @Email: baimoc@163.com
 * @Date: 2022-11-19 18:03:07
 * @LastEditTime: 2022-11-19 18:03:44
 * @FilePath: \transcriptome-browser\src\pages\Contact.jsx
 */
import React from 'react'

export default function Contact() {
  return (
    <div>Contact</div>
  )
}
