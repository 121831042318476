const file_options = [
  {
    label: "Load from File",
    key: "0",
  },
  {
    label: "Load from URL",
    key: "1",
  },
  {
    label: "Load from Server",
    key: "2",
  },
  {
    type: "divider",
  },
  {
    label: "New Session",
    key: "3",
  },
  {
    label: "Open Session",
    key: "4",
  },
  {
    label: "Save Session",
    key: "5",
  },
  {
    type: "divider",
  },
  {
    label: "Save PNG Image",
    key: "6",
  },
  {
    label: "Save SVG Image",
    key: "7",
  },
];

const edit_options = [
  {
    label: "Undo",
    key: "0",
  },
  {
    label: "Redo",
    key: "1",
  },
  {
    label: "History",
    key: "2",
  },
];

const genomes_options = [
  {
    label: "Load Genome from File",
    key: "0",
  },
  {
    label: "Load Genome from URL",
    key: "1",
  },
  {
    label: "Load Genome from Server",
    key: "2",
  },
  {
    type: "divider",
  },
  {
    label: "Remove Genomes",
    key: "3",
  },
];

const view_options = [
  {
    label: "Preferences",
    key: "0",
  },
  {
    label: "Color Legends",
    key: "1",
  },
  {
    type: "divider",
  },
  {
    label: "Show Name Panel",
    key: "2",
  },
  {
    label: "Set Name Panel Width",
    key: "3",
  },
  {
    label: "Show Attribute Display",
    key: "4",
  },
  {
    label: "Select Attributes to Show",
    key: "5",
  },
  {
    label: "Show Header Panel",
    key: "6",
  },
  {
    type: "divider",
  },
  {
    label: "Reorder Panels",
    key: "7",
  },
  {
    label: "Add New Panel",
    key: "8",
  },
];

const tracks_options = [
  {
    label: "Load Tracks from File",
    key: "0",
  },
  {
    label: "Load Tracks from URL",
    key: "1",
  },
  {
    label: "Load Tracks from Server",
    key: "2",
  },
  {
    type: "divider",
  },
  {
    label: "Sort Tracks",
    key: "3",
  },
  {
    label: "Group Tracks",
    key: "4",
  },
  {
    label: "Filter Tracks",
    key: "5",
  },
  {
    type: "divider",
  },
  {
    label: "Rename Tracks",
    key: "6",
  },
  {
    label: "Overlay Data Tracks",
    key: "7",
  },
  {
    label: "Fit Data to Window",
    key: "8",
  },
  {
    label: "Set Track Height",
    key: "9",
  },
];

const help_options = [
  {
    label: "Document",
    key: "0",
  },
  {
    label: "Contact us",
    key: "2",
  },
  {
    label: "About",
    key: "3",
  },
  {
    label: "Version 1.0.0",
    key: "4",
  },
];

export default {file_options, edit_options, genomes_options, view_options, tracks_options, help_options}