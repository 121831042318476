/*
 * @Author: Zheng Lei
 * @Email: baimoc@163.com
 * @Date: 2023-01-02 11:50:19
 * @LastEditTime: 2023-01-02 17:04:34
 * @FilePath: \transcriptome-browser\src\pages\panels\RNASequence.jsx
 */
import React from 'react'
import PanelMenu from '../../components/UEBrowser/PanelMenu'

export default function RNASequence() {
  return (
    <>
      <div className='panel-body' id='rna-sequence'>
        <PanelMenu to='/rnasequence' name='RNA Sequence' did='rna-sequence'></PanelMenu>
        <div className="panel-content">
        RNA Sequence Panel
        </div>
      </div>
    </>
  )
}
