/*
 * @Author: Zheng Lei
 * @Email: baimoc@163.com
 * @Date: 2022-11-19 18:02:43
 * @LastEditTime: 2022-11-20 17:33:47
 * @FilePath: \transcriptome-browser\src\pages\Plugins.jsx
 */

import React from "react";
import SplitPane from "react-split-pane";
import Pane from "../components/BmPane";

export default function Plugins() {
  return (
    <SplitPane split="vertical" defaultSize={200}>
      <SplitPane split="horizontal" defaultSize={200}>
        <Pane></Pane>
        <Pane initialSize="24%"></Pane>
      </SplitPane>
      <SplitPane split="horizontal" initialSize="30%">
        <Pane></Pane>
        <Pane></Pane>
        <Pane initialSize="24%"></Pane>
      </SplitPane>
    </SplitPane>
  );
}
