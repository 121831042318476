/*
 * @Author: Zheng Lei
 * @Email: baimoc@163.com
 * @Date: 2022-11-20 16:55:36
 * @LastEditTime: 2022-11-20 18:02:25
 * @FilePath: \transcriptome-browser\src\components\BmPane.jsx
 */
import React, { useState } from 'react';
import { PictureOutlined, ExpandAltOutlined, SettingOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
const items = [
  {
    label: '',
    key: 'mail',
    icon: <ExpandAltOutlined />,
  },
  {
    label: '',
    key: 'app',
    icon: <PictureOutlined />,
  },
  {
    label: '',
    key: 'SubMenu',
    icon: <SettingOutlined />,
    children: [
      {
        type: 'group',
        label: 'Item 1',
        children: [
          {
            label: 'Option 1',
            key: 'setting:1',
          },
          {
            label: 'Option 2',
            key: 'setting:2',
          },
        ],
      },
      {
        type: 'group',
        label: 'Item 2',
        children: [
          {
            label: 'Option 3',
            key: 'setting:3',
          },
          {
            label: 'Option 4',
            key: 'setting:4',
          },
        ],
      },
    ],
  }
];

export default function Pane() {
  const [current, setCurrent] = useState('mail');
  const onClick = (e) => {
      console.log('click ', e);
      setCurrent(e.key);
    };
    return <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" items={items} />;
}
