/*
 * @Author: Zheng Lei
 * @Email: baimoc@163.com
 * @Date: 2022-12-11 10:24:34
 * @LastEditTime: 2023-01-02 17:41:03
 * @FilePath: \transcriptome-browser\src\components\UEBrowser\PanelMenu.jsx
 */
import React, { useState } from "react";
import { useLocation } from 'react-router-dom'
import {
  CloseOutlined,
  QuestionOutlined,
  FullscreenOutlined,
  FullscreenExitOutlined,
  BlockOutlined,
  CameraOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import "../../styles/UEBrowser.css";
import { message, Popconfirm } from "antd";

export default function PanelMenu(props) {
  const [isFullscreen, setisFullscreen] = useState(false);
  let isMain = useLocation().pathname === "/main";
  

  function handleNewWindow() {
    console.log("NewWindow", props.name, props.to);
    window.open(
      props.to,
      props.name,
      "width=600,height=400,left=200,top=200,titlebar=no,status=no,toolbar=no,menubar=no,location=no"
    );
    // w.location.href='你的地址'
  }

  // 全屏
  const handleFullscreen = (did) => {
    const element = document.getElementById(did);
    console.log("handleFullscreen", did);

    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullScreen) {
      element.style.cssText = "";
      element.webkitRequestFullScreen();
    }
    setisFullscreen(true);
  };

  //退出全屏
  const exitFullscreen = (did) => {
    const fullscreenElement =
      document.fullscreenElement ||
      document.mozFullScreenElement ||
      document.webkitFullscreenElement;
    // const fullscreenEnabled = document.fullscreenEnabled || document.mozFullScreenEnabled || document.webkitFullscreenEnabled;
    if (document.exitFullscreen) {
      if (fullscreenElement) {
        document.exitFullscreen();
      } else {
      }
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitCancelFullScreen) {
      let element = document.getElementById(did);
      element.style.cssText = "";
      document.webkitCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
    setisFullscreen(false);
  };

  const handleClose = (did) => {
    let element = document.getElementById(did);
    let parent = element.parentNode;
    let gparent = parent.parentNode;
    let ggparent = gparent.parentNode;

    let currentIndex = getNodeIndex(parent);
    let nodeNum = gparent.childNodes.length;
    console.log(currentIndex, nodeNum)

    if(currentIndex == 0) {
      gparent.childNodes[nodeNum-1].style.height = "99.999%";
    }else if(currentIndex == nodeNum-1) {
      console.log(gparent.childNodes,nodeNum-3,gparent.childNodes[nodeNum-1])
      gparent.childNodes[nodeNum-3].style.height = "99.999%";
    }else{
      gparent.childNodes[currentIndex+2].style.height = "99.999%";
    }
    if (parent.nextElementSibling)
      gparent.removeChild(parent.nextElementSibling);
    
    gparent.removeChild(parent);
    let windowNum = gparent.childNodes.length;
    console.log(gparent.parentNode.previousElementSibling);
    if (!windowNum) {
      ggparent.parentNode.removeChild(ggparent.previousElementSibling);

      let res =
        ggparent.style.width + ggparent.previousElementSibling.style.width;
      console.log(
        ggparent.style.width,
        ggparent.previousElementSibling.style.width,
        res
      );

      ggparent.previousElementSibling.style.width = "100%";
      ggparent.parentNode.removeChild(ggparent);
    }
  };

  // 使用泛型方法获取子节点位于父节点的位置
  function getNodeIndex(node){ 
      let index = 0; 
      index = Array.prototype.indexOf.call(node.parentNode.children, node);
      return index; 
  }
  
  

  return (
    <div>
      <div className="panel-menu">
        <div className="panel-name">{props.name}</div>
        { isMain && 
          <BlockOutlined className="panel-menu-btn" onClick={handleNewWindow} />
        }

        {!isFullscreen && (
          <FullscreenOutlined
            className="panel-menu-btn"
            onClick={() => handleFullscreen(props.did)}
          />
        )}
        {isFullscreen && (
          <FullscreenExitOutlined
            className="panel-menu-btn"
            onClick={() => exitFullscreen(props.did)}
          />
        )}

        {
          isMain && 
          <Popconfirm
            placement="topRight"
            title="Close this panle"
            // description="Are you sure to close this panle?"
            onConfirm={() => handleClose(props.did)}
            // onCancel={cancelClose}
            okText="Yes"
            cancelText="No"
          >
            <CloseOutlined
              className="panel-menu-btn"
              // onClick={() => handleClose(props.did)}
            />
          </Popconfirm>
        }

            {/* <CloseOutlined
              className="panel-menu-btn"
              onClick={() => handleClose(props.did)}
            />   */}

        {/* <QuestionOutlined className="panel-menu-btn" /> */}
        {/* <CameraOutlined className="panel-menu-btn" /> */}
        {/* <ControlOutlined className="panel-menu-btn"/> */}
        {/* <SettingOutlined className="panel-menu-btn" /> */}
      </div>
    </div>
  );
}
