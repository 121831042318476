/*
 * @Author: Zheng Lei
 * @Email: baimoc@163.com
 * @Date: 2022-11-29 11:25:44
 * @LastEditTime: 2022-11-30 10:58:22
 * @FilePath: \transcriptome-browser\src\components\UEBrowser\Render\geneRenderer.jsx
 */
import React, { useEffect } from "react";
import * as d3 from "d3";

function drawFeature() {
  const data = [12, 5, 6, 6, 9, 10];
  const gene = [
    {
      id: "1",
      symbol: "Oct4",
      ensemble: "ENSG00000230402.2",
      exons: [
        [30, 35],
        [40, 50],
        [80, 150],
      ],
      start: 10,
      end: 200,
      strand: "+",
    },
  ];
  const intron_height = 1;
  const intron_color = "#4b7bec";
  const exon_height = 8;
  const exon_color = "#4b7bec";
  const exon_stroke_width = "1";
  const exon_stroke_color = "#3867d6";
  const symbol_padding = 5;
  const symbol_color = "#2f3542";
  const symbol_font_size = 12;
  let top = 100;
  const dir_width = 6;

  const svg = d3
    .select("#feature_container")
    .append("svg")
    .attr("width", window.innerWidth)
    .attr("height", 300)
    // .attr("style", "background: #f1f2f6")
    
  // let xScale = d3.scaleLinear()
  //     .domain([0, 500])
  //     .range([0, 250])
  
  // let xAxis = d3.axisBottom(xScale)
  //   .ticks(7)
  
  //   svg.append("g")
  //           .call(xAxis);

  gene.forEach((ele) => {
    let transcript = svg.append("g");
    transcript
    .append("rect")
    .attr("id", ele.symbol+"_layer")
    .attr("x", ele.start)
    .attr("y", top - 1)
    .attr("width", ele.end - ele.start)
    .attr("height", exon_height + symbol_font_size + symbol_padding)
    .attr("fill", "#dfe4ea")
    .attr("fill-opacity", "0");
    // render intron ------------------------------------
    transcript
      .append("rect")
      .attr("x", ele.start)
      .attr("y", top + exon_height / 2)
      .attr("width", ele.end - ele.start - dir_width / 2)
      .attr("height", intron_height)
      .attr("fill", intron_color);
    // render exon ------------------------------------
    ele.exons.forEach((exon) => {
      transcript
        .append("rect")
        .attr("x", exon[0] - ele.start)
        .attr("y", top)
        .attr("fill", exon_color)
        .attr("width", exon[1] - exon[0])
        .attr("height", exon_height)
        .attr("stroke-width", exon_stroke_width)
        .attr("stroke", exon_stroke_color);
    });
    // render strand ------------------------------------
    let direct = transcript.append("path").attr("fill", intron_color);
    if (ele.strand === "+") {
      direct.attr(
        "d",
        `M${ele.end - dir_width} ${top + intron_height / 2} L${
          ele.end - dir_width*2 / 3
        } ${top + exon_height / 2 + intron_height / 2} L${
          ele.end - dir_width
        } ${top + exon_height + intron_height / 2} L${ele.end} ${
          top + exon_height / 2 + intron_height / 2
        } Z`
      );
    } else {
      direct.attr(
        "d",
        `M${ele.start + dir_width} ${top + intron_height / 2} L${
          ele.start + dir_width*2 / 3
        } ${top + exon_height / 2 + intron_height / 2} L${
          ele.start + dir_width
        } ${top + exon_height + intron_height / 2} L${ele.start} ${
          top + exon_height / 2 + intron_height / 2
        } Z`
      );
    }
    // render transcript name ------------------------------------
    transcript
      .append("text")
      .attr("x", ele.start)
      .attr("y", top + exon_height * 2 + symbol_padding)
      .attr("font-color", symbol_color)
      .attr("font-size", symbol_font_size)
      .attr("font-weight", "600")
      .attr("pointer-events", "none")
      .attr("class", "no-selected")
      .text(ele.symbol);
      
     

    transcript.on("click", (e) => {
      console.log("click", e);
    });
    transcript.on("mouseover", function () {
      d3.select("#"+ele.symbol+"_layer").attr("fill-opacity", "1")
    });
    transcript.on("mouseout", function () {
      d3.select("#"+ele.symbol+"_layer").attr("fill-opacity", "0")
    });

    // transcript.append("text")
    // .attr("x", ele.start)
    // .attr("y", top + exon_height*3+symbol_padding)
    // .attr("font-size", "13")
    // .text(ele.ensemble)
    // transcript.append("polyline")
    // .attr("points", `${ele.end-dir_width} ${top+intron_height/2} ${ele.end-dir_width/2} ${top+exon_height/2+intron_height/2} ${ele.end-dir_width} ${top+exon_height+intron_height/2} ${ele.end} ${top+exon_height/2+intron_height/2}`)
  });
}

export default function GeneRenderer() {
  document.onselectstart = function () { 
    return false; 
};
  
  useEffect(() => {
    drawFeature();
    return () => {};
  }, []);
  return (
    <div>
      <div id="feature_container"></div>
    </div>
  );
}
