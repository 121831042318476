/*
 * @Author: Zheng Lei
 * @Email: baimoc@163.com
 * @Date: 2022-11-19 16:31:55
 * @LastEditTime: 2023-01-03 10:04:27
 * @FilePath: \transcriptome-browser\src\pages\Home.jsx
 */
import React from "react";
import { Col, Row, Divider } from "antd";
import {Link} from 'react-router-dom'
import {AppstoreAddOutlined, ChromeOutlined, BranchesOutlined, AimOutlined, SubnodeOutlined, GatewayOutlined, } from '@ant-design/icons'
import "../styles/Home.css";

export default function Home() {
  return (
    <div className="home-bg">
      <center>
        <div style={{ margin: "80px 0 0px 0px" }}>
          <div className="home-slogan1">UChicago</div>
          <div className="home-slogan2">Epitranscriptome Browser</div>
          <div className="home-slogan">
            The novel RNA-centric browser that runs on the web, on your
            desktop, or embedded in your app.
          </div>
          <div className="home-button"><Link to='/main' style={{color:"#fff"}}>Get Start</Link></div>
          {/* <div className="home-bow"></div> */}
        </div>
      </center>
      <Divider style={{ fontSize: "26px", color: "#2a445d" }}>Features</Divider>
      <Row justify="center" className="home-features">
        <Col xs={{ span: 8 }} lg={{ span: 6 }}>
          <center>
          <AimOutlined style={{fontSize: '50px'}} />
          <h3>RNA-centric</h3>
          <p>A novel RNA-centric platform is urgently needed for epitranscriptomic studies.</p>
          </center>
        </Col>
        <Col xs={{ span: 8 }} lg={{ span: 6 }}>
        <center>
        <SubnodeOutlined  style={{fontSize: '50px'}}/>
          <h3>Epitranscriptomic </h3>
          <p>Focused on RNA modification research.</p>
          </center>
        </Col>
        <Col xs={{ span: 8 }} lg={{ span: 6 }}>
        <center>
        <GatewayOutlined  style={{fontSize: '50px'}}/>
          <h3> Multimodal</h3>
          <p>Synchronously integrates and analyzes multimodal data</p>
          </center>
        </Col>
      </Row>
      <Row justify="center" className="home-features">
        <Col xs={{ span: 8 }} lg={{ span: 6 }}>
          <center>
          <AppstoreAddOutlined style={{fontSize: '50px'}} />
          <h3>Expandable</h3>
          <p>Realize the complete life cycle of application development and make it plug-in.</p>
          </center>
        </Col>
        <Col xs={{ span: 8 }} lg={{ span: 6 }}>
        <center>
        <ChromeOutlined  style={{fontSize: '50px'}}/>
          <h3>Cross Platform</h3>
          <p>Runs on modern browsers, desktop applications such as Windows, Ubuntu, macOS, etc.</p>
          </center>
        </Col>
        <Col xs={{ span: 8 }} lg={{ span: 6 }}>
        <center>
        <BranchesOutlined  style={{fontSize: '50px'}}/>
          <h3>Interaction Network</h3>
          <p>Predict and visualize Protein-RNA interaction networks</p>
          </center>
        </Col>
      </Row>

      <Divider style={{ fontSize: "26px", color: "#2a445d" }}>Cites</Divider>
      <div className="home-footer">
        <Row>
          <Col xs={{ span: 9 }} lg={{ span: 9 }}>
            <h2>Document</h2>
            <p>Tutorial</p>
            <p>Plugins</p>
          </Col>
          <Col xs={{ span: 9 }} lg={{ span: 9 }}>
            <h2>Development</h2>
            <p>GitHub</p>
            <p>Team</p>
            <p>Log</p>
          </Col>
          <Col xs={{ span: 6 }} lg={{ span: 6 }}>
            <h2>Contact</h2>
            <p>Blog</p>
            <p>Twitter</p>
            <p>Email</p>
          </Col>
        </Row>
        <center style={{ marginTop: "20px" }}>
          Copyright © 2022 UChicago.
        </center>
      </div>
    </div>
  );
}
