/*
 * @Author: Zheng Lei
 * @Email: baimoc@163.com
 * @Date: 2022-11-19 18:02:33
 * @LastEditTime: 2023-01-01 10:16:46
 * @FilePath: \transcriptome-browser\src\pages\UEBrowser.jsx
 */
import React, { useState } from "react";
import { Layout } from "antd";
import SplitPane from "react-split-pane";

import Menu from "../components/UEBrowser/Menu";
import PanelMenu from "../components/UEBrowser/PanelMenu";
import "../styles/UEBrowser.css";

import TranscriptomeBrowser from "./panels/TranscriptomeBrowser";
import ThreeDGenome from "./panels/3DGenome";
import InteractionNetwork from "./panels/InteractionNetwork";
import RNAExpression from "./panels/RNAExpression";
// import initialData from "../data/initial-data";
import {
  BankOutlined,
  BookOutlined,
  CloudServerOutlined,
  AppstoreAddOutlined,
  HistoryOutlined,
  HeartOutlined,
  SearchOutlined,
  CloseOutlined,
  QuestionOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
  BlockOutlined,
  CameraOutlined,
  ControlOutlined,
  SettingOutlined,
  ExclamationCircleOutlined,
  BugOutlined,
} from "@ant-design/icons";
import Home from "./Asider/Home";
import Search from "./Asider/Search";
import BookMark from "./Asider/BookMark";
import History from "./Asider/History";
import Cloud from "./Asider/Cloud";
import Plugins from "./Asider/Plugins";

const { Header, Footer, Sider, Content } = Layout;

export default function TBrowser() {
  const asideMixWidth = "100px";
  const [winHeight, setWinHeight] = useState(window.innerHeight);
  const [asideWidth, setAsideWidth] = useState("200px");
  const [asideMaxWidth, setasideMaxWidth] = useState("200px");
  const [asideElement, setasideElement] = useState(<Home />);
  const [asideLabel, setasideLabel] = useState("home");

  window.onresize = function () {
    console.log("window.onresize");
    setWinHeight(window.innerHeight);
    // app.renderer.resize(window.innerWidth, window.innerHeight);
  };

  const handleSideClick = (label) => {
    switch (label) {
      case "home":
        setasideElement(<Home />);
        break;
      case "search":
        setasideElement(<Search />);
        break;
      case "bookmark":
        setasideElement(<BookMark />);
        break;
      case "history":
        setasideElement(<History />);
        break;
      case "cloud":
        setasideElement(<Cloud />);
        break;
      case "plugins":
        setasideElement(<Plugins />);
        break;

      default:
        setasideElement(<Home />);
        break;
    }
    console.log(asideLabel, label);
    if (asideLabel !== label) {
      setasideLabel(label);
      console.log("==", asideLabel, label);
      return;
    }
    let element = document.getElementById("baimo-asider");
    let currentWidth = element.parentNode.style.width;
    console.log(currentWidth);

    if (currentWidth)
      parseInt(currentWidth.replace("px", "")) >
      parseInt(asideMixWidth.replace("px", ""))
        ? setasideMaxWidth(currentWidth)
        : asideMixWidth;
    setAsideWidth(currentWidth === "0px" ? asideMaxWidth : "0px");
  };

  return (
    <div>
      <Layout>
        <Header>
          <Menu></Menu>
        </Header>
        <Layout style={{ height: winHeight - 54 }}>
          <Sider style={{ width: "48px" }} width="48px">
            <div className="sider-container">
              <BankOutlined
                key="home"
                className="aside-btn"
                onClick={() => handleSideClick("home")}
              />
              <SearchOutlined
                key="search"
                className="aside-btn"
                onClick={() => handleSideClick("search")}
              />
              <BookOutlined
                key="bookmark"
                className="aside-btn"
                onClick={() => handleSideClick("bookmark")}
              />

              <HistoryOutlined
                key="histroy"
                className="aside-btn"
                onClick={() => handleSideClick("history")}
              />
              {/* <HeartOutlined key="love" className="aside-btn"  onClick={()=>handleSideClick('heart')}/> */}
              <CloudServerOutlined
                key="cloud"
                className="aside-btn"
                onClick={() => handleSideClick("cloud")}
              />
              <AppstoreAddOutlined
                key="plugins"
                className="aside-btn"
                onClick={() => handleSideClick("plugins")}
              />
            </div>
          </Sider>
          <Content>
            <SplitPane split="vertical">
              <div
                id="baimo-asider"
                initialSize={asideWidth}
                style={{ height: "100%", width: "100%" }}
              >
                {asideElement}
              </div>
              <div initialSize="60%" minSize="280px">
                <SplitPane split="horizontal">
                  <div
                    initialSize="60%"
                    className="panel-body"
                    id="transcriptome-browser"
                  >
                    <TranscriptomeBrowser></TranscriptomeBrowser>
                  </div>
                  {/* <SplitPane initialSize="40%" split="vertical">
                <div initialSize={"60%"} className="panel-body">
                  <PanelMenu
                  to="/tsbrowser"
                  name="RNA Sequence Modification"
                  initialSize={"30px"}
                  minSize="30px"
                  maxSize="30px"
                  ></PanelMenu>
                </div>

                <div initialSize={"40%"} className="panel-body">
                  <PanelMenu
                  to="/tsbrowser"
                  name="RNA Structure"
                  initialSize={"30px"}
                  minSize="30px"
                  maxSize="30px"
                  ></PanelMenu>
                </div>
                </SplitPane> */}
                </SplitPane>
              </div>
              <SplitPane initialSize="40%" split="horizontal">
                <div
                  initialSize={"40%"}
                  className="panel-body"
                  id="rna-expression"
                  minSize="30px"
                >
                  <PanelMenu
                    to="/rnaexpression"
                    name="RNA Expression Image"
                    did="rna-expression"
                    initialSize={"30px"}
                  ></PanelMenu>
                  <RNAExpression></RNAExpression>
                </div>
                <div
                  initialSize={"20%"}
                  className="panel-body"
                  id="threed-genome"
                  minSize="30px"
                >
                  <PanelMenu
                    to="/3dgenome"
                    name="3D Genome"
                    did="threed-genome"
                    initialSize={"30px"}
                  ></PanelMenu>
                  <ThreeDGenome></ThreeDGenome>
                </div>
                <div
                  initialSize={"20%"}
                  className="panel-body"
                  id="interaction-network"
                  minSize="30px"
                >
                  <PanelMenu
                    to="/interactionnetwork"
                    name="Interaction Network"
                    did="interaction-network"
                    initialSize={"30px"}
                  ></PanelMenu>
                  <InteractionNetwork></InteractionNetwork>
                </div>
                <div
                  initialSize={"20%"}
                  className="panel-body"
                  id="interaction-network1"
                  minSize="30px"
                >
                  <PanelMenu
                    to="/interactionnetwork"
                    name="RNA Structure"
                    did="interaction-network1"
                    initialSize={"30px"}
                  ></PanelMenu>
                  <InteractionNetwork></InteractionNetwork>
                </div>
              </SplitPane>
            </SplitPane>
          </Content>
        </Layout>
        <Footer>
          <ExclamationCircleOutlined className="footer-btn" />
          <BugOutlined className="footer-btn" />
        </Footer>
      </Layout>
    </div>
  );
}
