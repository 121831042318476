/*
 * @Author: Zheng Lei
 * @Email: baimoc@163.com
 * @Date: 2022-12-25 16:33:01
 * @LastEditTime: 2023-01-02 11:41:40
 * @FilePath: \transcriptome-browser\src\data\track-data.js
 */
import { nanoid } from "nanoid";

// 数据初始化
let track_num = 5;
let track_ele_max = 4000;
let trackData = [];
let colors = [
  "0xffa502",
  "0xff6348",
  "0xff4757",
  "0x2ed573",
  "0x1e90ff",
  "0x3742fa",
  "0xeccc68",
  "0xff7f50",
  "0x5352ed",
  "0xffa502",
  "0xff6348",
  "0xff4757",
  "0x2ed573",
  "0x1e90ff",
  "0x3742fa",
  "0xeccc68",
  "0xff7f50",
  "0x5352ed",
];
for (let j = 0; j < track_num; j++) {
  let values = [];
  for (let index = 0; index < track_ele_max; index++) {
    values.push(Math.round(Math.random() * 50));
  }
  trackData.push({
    id: j,
    name: "Track" + j,
    type: "track",
    values: values,
    style: {
      background: 0xf1f2f6,
      color: colors[j],
      height: 50,
    },
  });
}

trackData.push({
  id: nanoid(),
  name: "Human hg19",
  type: "genome",
  style: {
    height: 50,
  },
});
trackData.push({
  id: nanoid(),
  name: "Gene",
  type: "annotation",
  style: {
    height: 200,
  },
});


export default trackData;