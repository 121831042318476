/*
 * @Author: Zheng Lei
 * @Email: baimoc@163.com
 * @Date: 2022-12-28 16:59:11
 * @LastEditTime: 2023-01-02 17:05:15
 * @FilePath: \transcriptome-browser\src\pages\panels\RNAExpression.jsx
 */
import React from 'react'
import PanelMenu from '../../components/UEBrowser/PanelMenu'

export default function RNAExpression() {
  return (
    <>
      <div className='panel-body' id='rna-expression'>
        <PanelMenu to='/rnaexpression' name='RNA Expression Image' did='rna-expression'></PanelMenu>
        <div className="panel-content">
        RNA Expression Panel
        </div>
      </div>
    </>
  )
}
