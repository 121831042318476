/*
 * @Author: Zheng Lei
 * @Email: baimoc@163.com
 * @Date: 2022-12-10 09:51:10
 * @LastEditTime: 2023-01-02 19:13:08
 * @FilePath: \transcriptome-browser\src\components\UEBrowser\Menu.jsx
 */
import React from "react";
import { Button, Dropdown, message } from "antd";
import "../../styles/UEBrowser.css";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  ReloadOutlined,
  FullscreenOutlined,
  HomeOutlined,
  MenuOutlined,
  FolderOpenOutlined,
  PlusCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import MenuData from '../../data/menu-data'

export default function Menu() {
  const {file_options, edit_options, genomes_options, view_options, tracks_options, help_options} = MenuData

  const onClickFile = ({key})=>{
    message.info(`Click File index  ${key}`);
  }


  const onClickEdit = ({key})=>{
    message.info(`Click Edit index ${key}`);
  }

  const onClickGenomes = ({key})=>{
    message.info(`Click Genomes index ${key}`);
  }

  const onClickView = ({key})=>{
    message.info(`Click View index ${key}`);
  }
  
  const onClickTracks = ({key})=>{
    message.info(`Click Tracks index ${key}`);
  }
  
  const onClickHelp = ({key})=>{
    message.info(`Click Help index ${key}`);
  }
  
  return (
    <div>
      <div className="menu-bar">
        <Dropdown
          menu={{
            items: file_options,
            onClick:onClickFile,
          }}
          trigger={["hover"]}
        >
          <Button type="text">File</Button>
        </Dropdown>
        <Dropdown
          menu={{
            items: edit_options,
            onClick:onClickEdit,
          }}
          trigger={["hover"]}
        >
          <Button type="text">Edit</Button>
        </Dropdown>
        <Dropdown
          menu={{
            items: genomes_options,
            onClick:onClickGenomes,
          }}
          trigger={["hover"]}
        >
          <Button type="text">Genome</Button>
        </Dropdown>
        <Dropdown
          menu={{
            items: view_options,
            onClick:onClickView,
          }}
          trigger={["hover"]}
        >
          <Button type="text">View</Button>
        </Dropdown>
        <Dropdown
          menu={{
            items: tracks_options,
            onClick:onClickTracks,
          }}
          trigger={["hover"]}
        >
          <Button type="text">Tracks</Button>
        </Dropdown>
        <Dropdown
          menu={{
            items: help_options,
            onClick:onClickHelp
          }}
          trigger={["hover"]}
        >
          <Button type="text">Help</Button>
        </Dropdown>
      </div>
    </div>
  );
}
