/*
 * @Author: Zheng Lei
 * @Email: baimoc@163.com
 * @Date: 2023-01-01 15:39:31
 * @LastEditTime: 2023-01-01 15:43:31
 * @FilePath: \transcriptome-browser\src\components\RouterLayout.jsx
 */
import { useCallback, useEffect, useMemo } from 'react';
import { useLocation,  useMatch, matchPath } from 'react-router-dom';
import {navbarData} from '../routes/index.jsx'
// 树形转数组
export const treeToArray = (arrayList) => {
  const list = [];
  const listFun = (ll) => {
      ll.map(li => {
              list.push(li);
              const children = li.children
              if (children && Array.isArray(children)) {
                  listFun(children.slice(0))
              }
              return list
      })
      return list
  }
  listFun(arrayList)
  return list
}

const Layout = ({  children }) => {
    const { pathname } = useLocation();

    const routerTitle = useMemo(() => {
        const list = treeToArray(navbarData); // 如果你的路由不是树形的，可以不用转
        const title = list.filter(route => route['title'] && matchPath(route.path,pathname))
        return Array.isArray(title) && title.length ? title[0]['title'] : '标题'
    }, [navbarData, pathname])

    useEffect(() => {
      document.title = routerTitle
    }, [routerTitle])
    return (
        <div >
            {children}
        </div>
    );
};

export default Layout;

