/*
 * @Author: Zheng Lei
 * @Email: baimoc@163.com
 * @Date: 2022-12-31 16:32:33
 * @LastEditTime: 2022-12-31 16:32:36
 * @FilePath: \transcriptome-browser\src\pages\Asider\Search.jsx
 */
import React from 'react'

export default function Search() {
  return (
    <div>Search</div>
  )
}
