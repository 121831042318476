/*
 * @Author: Zheng Lei
 * @Email: baimoc@163.com
 * @Date: 2022-11-24 09:25:12
 * @LastEditTime: 2022-11-29 10:41:23
 * @FilePath: \transcriptome-browser\src\components\UEBrowser\Track.jsx
 */
import React from 'react'
import { Menu, Item, Separator, Submenu, useContextMenu,RightSlot } from 'react-contexify';

import BaseRender from './Render/BaseRender'
import Aside from '../UEBrowser/Aside'
// import 'react-contexify/ReactContexify.css';

const track_setting = {}
const MENU_ID = 'blahblah';


export default function track(props) {
  const {data} = props
  let tracks_data = []

  data.forEach((ele)=> {
    if(ele.type === "track"){
      tracks_data.push(ele)
    }
  })

  const { show } = useContextMenu({
    id: MENU_ID,
  });

  function handleContextMenu(event){
      show({
        event,
        props: {
            key: 'value'
        }
      })
  }

  // I'm using a single event handler for all items
  // but you don't have too :)
  const handleItemClick = ({ id, event, props }) => {
    switch (id) {
      case "copy":
        console.log(event, props)
        break;
      case "cut":
        console.log(event, props);
        break;
      //etc...
    }
  }

  return (
    <div  onContextMenu={handleContextMenu} style={{position: 'relative'}}>
        <Menu id={MENU_ID}>
          <Item disabled style={{color: "#000000"}}>{track.name}</Item>
          <Separator />
          <Item id="copy" onClick={handleItemClick}>Rename Track <RightSlot>⌘C</RightSlot></Item>
          <Item id="copy" onClick={handleItemClick}>Set Track Color <RightSlot>⌘C</RightSlot></Item>
          <Item id="copy" onClick={handleItemClick}>Set Track Height <RightSlot>⌘C</RightSlot></Item>
          <Item id="copy" onClick={handleItemClick}>Set Font Size <RightSlot>⌘C</RightSlot></Item>
          <Item id="cut" onClick={handleItemClick}>Export track names <RightSlot>⌘X</RightSlot></Item>
          <Item id="cut" onClick={handleItemClick}>Remove Track <RightSlot>⌘X</RightSlot></Item>
          <Separator />
          <Submenu label="Graph">
            <Item id="cut" onClick={handleItemClick}>Heatmap <RightSlot>⌘X</RightSlot></Item>
            <Item id="cut" onClick={handleItemClick}>Bar <RightSlot>⌘X</RightSlot></Item>
            <Item id="cut" onClick={handleItemClick}>Points <RightSlot>⌘X</RightSlot></Item>
            <Item id="cut" onClick={handleItemClick}>Line <RightSlot>⌘X</RightSlot></Item>
          </Submenu>
          <Submenu label="Function">
            <Item id="cut" onClick={handleItemClick}>Mean <RightSlot>⌘X</RightSlot></Item>
            <Item id="cut" onClick={handleItemClick}>90th Percentile <RightSlot>⌘X</RightSlot></Item>
            <Item id="cut" onClick={handleItemClick}>None <RightSlot>⌘X</RightSlot></Item>
          </Submenu>
          <Submenu label="Scale">
            <Item id="cut" onClick={handleItemClick}>Set Data Range <RightSlot>⌘X</RightSlot></Item>
            <Item id="cut" onClick={handleItemClick}>Set Heatmap Scale <RightSlot>⌘X</RightSlot></Item>
            <Item id="cut" onClick={handleItemClick}>Log Scale <RightSlot>⌘X</RightSlot></Item>
            <Item id="cut" onClick={handleItemClick}>Auto Scale <RightSlot>⌘X</RightSlot></Item>
            <Item id="cut" onClick={handleItemClick}>Show Data Range <RightSlot>⌘X</RightSlot></Item>
          </Submenu>
          <Submenu label="Save">
            <Item id="cut" onClick={handleItemClick}>Save PNG image <RightSlot>⌘X</RightSlot></Item>
            <Item id="cut" onClick={handleItemClick}>Save SVG image <RightSlot>⌘X</RightSlot></Item>
          </Submenu>
          
        </Menu>
      {
        tracks_data.map((track)=>{
          if (track.type === "track")
          return <BaseRender key={track.name} data={track} total={data.length}  onContextMenu={handleContextMenu}></BaseRender>
        })
      }
      <Aside data={tracks_data} initialSize="200px"></Aside>
    </div>
  )
}
