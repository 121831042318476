/* eslint-disable */
/*
 * @Author: Zheng Lei
 * @Email: baimoc@163.com
 * @Date: 2022-11-24 09:28:29
 * @LastEditTime: 2022-11-29 11:41:26
 * @FilePath: \transcriptome-browser\src\components\UEBrowser\Gene.jsx
 */

import React from "react";
import Aside from '../UEBrowser/Aside'
import GeneRenderer from './Render/GeneRenderer'

export default function Gene(props) {
  const {data:track_data} = props

  return (  
  <div style={{position: 'relative'}}>
    <Aside data={track_data} initialSize="200px"></Aside>
    <GeneRenderer></GeneRenderer>
  </div>
  )
}
